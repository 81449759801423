.navlink:active,
.navlink:hover,
.navlink:first-child {
    text-decoration: none;
    color: black;
}
.selected{
   border-bottom: 1px solid black;
}
.container{
    width: 15%;
    display: flex;
    justify-content: space-evenly;
    position: fixed;
    z-index: 1000;
}

@media only screen and (max-width:540px){
    .navlink{
        padding: 0 5px;
    }
}