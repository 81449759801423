.containers {
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	max-width: 600px;
	padding-top: 2.8em;
	position: relative;
}
.h1p {
	padding-left: 3px;
}
.button {
	background: black;
	color: white;
	border: none;
	width: 40px;
	height: 40px;
	min-width: 40px;
	border-radius: 100%;
	outline: none;
	transition: all 0.2s ease;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin-top: 10px;
}
.button .play-icon {
	height: 18px;
	width: 18px;
	margin-left: 4px;
	margin-top: 1px;
}
.button .pause-icon {
	height: 29px;
	width: 29px;
}

/*button:hover {*/
/*    background: #d63d05;*/
/*}*/

.playlist {
	text-align: left;
	border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.playlist-item {
	color: #444;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	cursor: pointer;
	padding: 1em;
}

.playlist-item.selected {
	font-weight: bold;
}

.playlist-item:hover {
	background: rgba(0, 0, 0, 0.1);
}

.controls {
	margin: 0 auto;
	padding: 1em;
}

.scroll-audio {
	position: absolute;
	top: 54%;
	width: 100%;
	z-index: 10;
	transform: scaleY(9);
	opacity: 0;
}
.audio-wave {
}
.audio-wave wave {
	border-right: 0 !important;
}
.meta {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin-bottom: 34px;
}
.meta .title {
	text-align: center;
	font-size: 30px;
	line-height: 1.2;
	font-family: Times New Roman;
	color: #201f1e;
}
.meta .sub-title {
	text-align: center;
	font-size: 16px;
	line-height: 1.2;
	font-family: Times New Roman;
	font-weight: 300;
	color: #201f1e;
	margin-top: 6px;
}
.meta.videos {
	margin-top: 30px;
}

@media (max-width: 992px) {
	.meta .title {
		font-size: 22px;
	}
	.meta .sub-title {
		font-size: 14px;
	}
}

@media (max-width: 480px) {
	.meta {
		padding: 0px 4px;
	}
	.audio-wave {
		padding: 0px 30px 0px 40px;
	}
	.audio-wave wave {
		height: 160px;
	}
	.scroll-audio {
		top: 52%;
	}
}
